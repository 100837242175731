import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioRecordService {
  private dataSubject: BehaviorSubject<any>;

  private audioLoading = new BehaviorSubject<Boolean>(false);
  loadingStatus = this.audioLoading.asObservable();

  constructor() {
    this.dataSubject = new BehaviorSubject<any>(null);
  }

  getData() {
    return this.dataSubject.asObservable();
  }

  updateData(newData: any) {
    this.dataSubject.next(newData);
  }

  removeData() {
    this.dataSubject.next(null);
  }

  isLoadingStatus(status) {
    this.audioLoading.next(status);
  }
}
